import { config } from "./installConfig";

export const defaultSettings = {
  percup: 10,
  markup: 1,
  username: "",
};

export const api_base = config.apiBase;

export const app_name = config.appName;

export const addBan = (username, callback) => {
  fetch(
    api_base + "/logins/get-all",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        username,
      }),
    },
    (res) => console.log(res)
  )
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      callback(data);
      return data;
    });
};

export const getLogins = (username, callback) => {
  fetch(
    api_base + "/logins/get-all",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        username,
      }),
    },
    (res) => console.log(res)
  )
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      callback(data);
      return data;
    });
};

export const searchNew = (val, callback) => {
  let userinfo = JSON.parse(localStorage["userinfo"]);
  const params = {
    username: userinfo.username,
    password: userinfo.password,
    oem: val,
  };

  console.log("sending params", params);

  fetch(api_base + "/parts/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      console.log("got data", data);
      const settings = localStorage["usersettings"]
        ? JSON.parse(localStorage["usersettings"])
        : null;

      if (settings) {
        for (let row of data) {
          if (settings.offsetType === "perc") {
            row.price = (
              parseFloat(row.price) * parseFloat(settings.offset)
            ).toFixed(2);
          } else {
            row.price = (
              parseFloat(row.price) + parseFloat(settings.offset)
            ).toFixed(2);
          }
        }
      }

      callback(val, data);
    });
};

export const getSearches = (username, callback) => {
  fetch(api_base + "/searches/get-by-user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({ username: username }),
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      callback(data);
    });
};

export const refreshUserInfo = async (username, password, callback) => {
  login(username, password, (user) => {
    if (user !== null && user !== "not_found") {
      localStorage["userinfo"] = JSON.stringify(user);
    } else {
      alert("User not found or login incorrect.");
      localStorage.removeItem("userinfo");
      localStorage.removeItem("offlinelist");
    }
    callback(true);
  });
};

export const login = (username, password, callback) => {
  console.log("logging in with ", username, password);
  const agent = navigator.userAgent;
  try {
    fetch(
      api_base + "/user/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          username,
          password,
          agent,
        }),
      },
      (res) => console.log(res)
    )
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        if (data.username === "not_found") {
          console.log("not_found");
          callback("not_found");
        } else {
          console.log("calling back with ", data);
          callback(data);
        }
        //callback(data);
        return data;
      });
  } catch (err) {
    console.log("err", err);
  }
};

export const getOfflineList = (hard_fetch, pricelist_id, callback) => {
  const userinfo = JSON.parse(localStorage["userinfo"]);
  console.log("getting offline list", hard_fetch, pricelist_id);
  fetch(
    api_base + "/pricelists/offline",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        username: userinfo.username,
        password: userinfo.password,
        pricelist_id: hard_fetch ? "" : pricelist_id,
      }),
    },
    (res) => console.log(res)
  )
    .then((res) => {
      console.log("res", res);
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      if (data.username === "not_found") {
        callback("not_found");
      } else {
        callback(data);
      }
      //callback(data);
      return data;
    });
};

export const loadUsers = (callback) => {
  fetch(api_base + "/users/getAll", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    //body: JSON.stringify({ user })
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      console.log("loaded users", data);
      callback(data);
    });
};

export const updateUser = (user) => {
  fetch(api_base + "/users/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      user: user,
    }),
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {});
};

export const updateUserSearches = (user) => {
  fetch(api_base + "/users/searches/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      user_id: user._id,
    }),
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {});
};

export const addUser = (user) => {
  fetch(api_base + "/users/put", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      user: user,
    }),
  })
    .then((res) => {
      if (res) {
        console.log("res", res);
        return res.json();
      }
      return null;
    })
    .then((data) => {});
};
