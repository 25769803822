import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login";
import ManagePricelists from "./components/ManagePricelists";
import AssignPricelists from "./components/AssignPricelists";
import ManageUsers from "./components/ManageUsers";
import Stats from "./components/Stats";
import Search from "./components/Search";
import UserSettings from "./components/UserSettings";

const routing = (
  <Router>
    <div>
      <Switch>
        <Route path="/stats">
          <Stats />
        </Route>
        <Route path="/search">
          <Search />
        </Route>
        <Route path="/manage-pricelists">
          <ManagePricelists />
        </Route>
        <Route path="/manage-users">
          <ManageUsers />
        </Route>
        <Route path="/assign-pricelists">
          <AssignPricelists />
        </Route>
        <Route path="/user-settings">
          <UserSettings />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </div>
    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <div style={{ width: "50%" }}>
        {/* <p
          style={{
            fontStyle: "italic",
            textAlign: "center",
            marginTop: "51px",
          }}
        >
          Due to current market volatility, if you have not already contacted us
          to book units you will receive the current price you are seeing on the
          app. Please contact us at (606) 274-3955 if you would like to lock in
          units at these prices.
        </p> */}
      </div>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
